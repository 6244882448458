const UserRoles =  [
    {
        "label": "Admin",
        "value": "admin"
    },
    {
        "label": "Accounting",
        "value": "accounting"
    },
    {
        "label": "Manager",
        "value": "manager"
    },
    {
        "label": "Jogger",
        "value": "jogger"
    }
];
export default UserRoles;