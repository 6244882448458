<template>
  <CRow>
    <CCol col="12" lg="6">
      <CCard>
        <CCardHeader style="font-weight:bold;">
          {{ userVal.name }}
          <div class="float-right" style="color:#ff0000;" @click="onRemoveUser">
            <CIcon name="cil-trash" size="lg" />
          </div>
        </CCardHeader>
        <CCardBody>
          <CInput
            label="Name"
            placeholder="Enter users name"
            v-model="userVal.name"
          />
          <CInput
            label="Email"
            placeholder="Enter users email"
            v-model="userVal.email"
          />
          <CInput
            label="Phone"
            prepend="+1"
            placeholder="Enter users phone"
            v-model="userVal.phone_number"
          />
          <CInput
            v-if="!isEditMode"
            label="Password"
            placeholder="Enter password"
            type="password"
            v-model="userVal.password"
          />
          <label>Sites</label>
          <CMultiSelect
            v-if="showSite"
            :options="siteData"
            :multiple="true"
            :search="true"
            :selected="selectedSites"
            :selection="true"
            optionsEmptyPlaceholder="No sites available"
            searchPlaceholder="Please select or search"
            selectionType="tags"
            @update="onSiteSelectionChange"
          >
            <!-- <option
              v-for="site of siteData"
              :key="site.site_id"
              :value="site.site_id"
              >{{ site.site_name }}</option -->
            >
          </CMultiSelect>
          <CRow style="margin-top:15px;">
            <CCol sm="8">
              <CSelect
                label="Group"
                placeholder="Please select"
                :options="roles"
                :value.sync="userVal.group"
              >
              </CSelect>
            </CCol>
            <CCol sm="4" class="text-center">
              <CCol tag="label" sm="12" class="col-form-label">
                Is Active
              </CCol>
              <CSwitch
                class="mr-1"
                color="success"
                :checked.sync="userVal.status"
                shape="pill"
                v-model="userVal.sites"
              />
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol sm="6"
              ><CButton color="light" @click="goBack">Cancel</CButton></CCol
            >
            <CCol sm="6" class="text-right"
              ><CButton color="primary" @click="onSaveEditClick"
                >Save</CButton
              ></CCol
            >
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
//import usersData from "./UsersData";
import AuthenticationService from "../../services/authenticationservice.js";
import DynamoDBService from "../../services/dynamodbservice.js";
import store from "../../store";
import { CognitoUser } from "amazon-cognito-identity-js";
import { Auth } from "aws-amplify";
import UserRoles from "../../data/Roles.js";
import constants from "../../data/Constants.js";
export default {
  name: "User",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("users");
    });
  },
  data() {
    return {
      selectedSites: [],
      showSite: false,
      siteData: [],
      siteFullDetails: [],
      isEditMode: false,
      credentials: null,
      usersOpened: null,
      roles: UserRoles,
      userVal: {
        name: "",
        email: "",
        group: "",
        status: true,
        password: "",
        sites: "",
        phone_number: "",
      },
    };
  },
  mounted: function() {
    this.userData();
    this.getSiteItems();
  },
  computed: {
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" },
      ];
    },
  },
  created() {
    if (this.credentials) {
      // have credentials
      console.log("in created(), have credentials");
    } else {
      // no credentials
      console.log("in created(), getting credentials...");
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getCredentials().then(
        (data) => {
          this.credentials = data;
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    }
  },
  methods: {
    onSiteSelectionChange($event) {
      this.selectedSites = $event;
      this.userVal.sites = $event.join(",");
    },
    userData() {
      if (this.$route.query.selectedData != undefined) {
        this.isEditMode = true;
        const selectedUser = this.$route.query.selectedData;
        this.userVal.name = this.$route.query.selectedData.name;
        this.userVal.email = this.$route.query.selectedData.email;

        this.userVal.group = this.$route.query.selectedData.group;
        this.userVal.sites = this.$route.query.selectedData.sites;
        this.userVal.phone_number = this.$route.query.selectedData.phone_number.substring(
          2
        );
        this.selectedSites = this.userVal.sites.split(",");
        if (this.$route.query.selectedData.status) {
          this.userVal.status = true;
        } else {
          this.userVal.status = false;
        }
      } else {
        this.isEditMode = false;
      }
    },
    goBack() {
      this.$router.push({ path: "/users" });
    },
    deleteDBUser() {
      var params = {
        TableName: constants.USERS_TABLE_NAME,
        Key: {
          userid: this.userVal.email,
        },
        ReturnValues: "NONE",
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.deleteItem(params).then(
        (data) => {
          console.log(data);
          this.$router.push({ path: "/users" });
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    async onRemoveUser() {
      this.deleteDBUser();
      /* try {
        const user = await Auth.currentAuthenticatedUser();
        user.deleteUser((error, data) => {
          if (error) {
            throw error;
          } else {
            this.deleteDBUser();
          }
        });
      } catch (error) {
        this.deleteDBUser();
      }*/
    },
    ValidateEmail(mail) {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          mail
        )
      ) {
        return true;
      }
      return false;
    },
    onSaveEditClick() {
      if (
        this.userVal.name == undefined ||
        this.userVal.name == null ||
        this.userVal.name == ""
      ) {
        this.$alert(constants.NAME_VALIDATION, constants.INFORMATION);
        return;
      }
      if (
        this.userVal.email == undefined ||
        this.userVal.email == null ||
        this.userVal.email == ""
      ) {
        this.$alert(constants.EMAIL_VALIDATION, constants.INFORMATION);
        return;
      }
      if (!this.ValidateEmail(this.userVal.email)) {
        this.$alert(constants.EMAIL_VALID_VALIDATION, constants.INFORMATION);
        return;
      }
      if (
        this.userVal.phone_number == undefined ||
        this.userVal.phone_number == null ||
        this.userVal.phone_number == ""
      ) {
        this.$alert(constants.PHONE_VALIDATION, constants.INFORMATION);
        return;
      }
      if (!this.isEditMode) {
        if (
          this.userVal.password == undefined ||
          this.userVal.password == null ||
          this.userVal.password == ""
        ) {
          this.$alert(constants.PASSWORD_VALIDATION, constants.INFORMATION);
          return;
        }
      }
      if (
        this.userVal.group == undefined ||
        this.userVal.group == null ||
        this.userVal.group == ""
      ) {
        this.$alert(constants.GROUP_VALIDATION, constants.INFORMATION);
        return;
      }

      if (this.isEditMode) {
        this.onEditData();
      } else {
        this.onSaveData();
      }
    },
    onEditData() {
      var check = this.userVal.phone_number.includes("+1");
      if (check == false) {
        this.userVal.phone_number = "+1" + this.userVal.phone_number;
      }
      var params = {
        TableName: constants.USERS_TABLE_NAME,
        Key: { userid: this.userVal.email },

        UpdateExpression:
          "set #v1 = :nameVal, #v2 = :statusVal, #v3 = :groupVal, #v4 = :sitesVal, #v5 = :phoneVal",
        ExpressionAttributeNames: {
          "#v1": "name",
          "#v2": "status",
          "#v3": "jogg_role",
          "#v4": "sites",
          "#v5": "phone_number",
        },
        ExpressionAttributeValues: {
          ":nameVal": this.userVal.name,
          ":statusVal": this.userVal.status,
          ":groupVal": this.userVal.group,
          ":sitesVal": this.userVal.sites,
          ":phoneVal": this.userVal.phone_number,
        },

        ReturnValues: "ALL_NEW",
      };

      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.updateItem(params).then(
        (data) => {
          console.log(data);
          this.$router.push({ path: "/users" });
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    onSaveData() {
      var check = this.userVal.phone_number.includes("+1");
      if (check == false) {
        this.userVal.phone_number = "+1" + this.userVal.phone_number;
      }
      const authenticationService = new AuthenticationService();
      authenticationService
        .createUser(
          this.userVal.email,
          this.userVal.password,
          this.userVal.phone_number
        )
        .then(
          (data) => {
            this.authInfo = data;

            var params = {
              TableName: constants.USERS_TABLE_NAME,
              Item: {
                userid: this.userVal.email,
                name: this.userVal.name,
                sites: this.userVal.sites,
                jogg_role: this.userVal.group,
                status: this.userVal.status,
                phone_number: this.userVal.phone_number,
              },
            };
            const dynamoDBService = new DynamoDBService();
            this.credentials = dynamoDBService.getCredentials();
            dynamoDBService.addItem(params).then(
              (data) => {
                console.log(data);
                this.$router.push({ path: "/users" });
              },
              (error) => {
                console.log(error);
                this.$alert(error.message, constants.INFORMATION);
              }
            );
          },
          (error) => {
            console.log(error);
            this.$alert(error.message, constants.INFORMATION);
          }
        );
    },
    getSiteItems() {
      var that = this;
      var params = {
        TableName: constants.SITES_TABLE_NAME,
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getAllItems(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.siteData = [];
            that.siteFullDetails = [];
            data.Items.forEach((element) => {
              var temp = { value: 0, text: "" };
              temp.value = element.site_id;
              temp.text = element.site_name;
              that.siteData.push(temp);
            });

            that.siteFullDetails = data.Items;
            that.showSite = false;
            that.$nextTick().then(function() {
              that.showSite = true;
            });
          }
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
  },
};
</script>
